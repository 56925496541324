import { notifyMe } from '../../libs/toast'
import router from '../../router/index'
import store from '../index'

export default {
  namespaced: true,
  state: {
    userRegister: {
      first_name: '',
      last_name: '',
      password: 'Kofi&Daniel!',
      confirm_password: 'Kofi&Daniel!',
      phone: '',
      profession: '',
      job_title: '',
      email: '',
      user_type: '',
      address1: '',
      city: '',
      state: '',
      zip_code: '',
      county: '',
    },
  },

  getters: {
    getUser(state) {
      return state.user
    },
    getUsers(state) {
      return state.users
    },
  },

  mutations: {
    EMPTY_DATA(state, data) {
      state.userRegister = data
    },
  },

  actions: {
    emptyData({ commit }) {
      const data = {
        first_name: '',
        last_name: '',
        password: 'Kofi&Daniel!',
        confirm_password: 'Kofi&Daniel!',
        profession: '',
        job_title: '',
        phone: '',
        email: '',
        user_type: '',
        address1: '',
        city: '',
        state: '',
        zip_code: '',
        county: '',
      }
      commit('EMPTY_DATA', data)
    },
    loginUser({ commit }, data) {
      window.cxSocketApi.talk('login', response => {
        localStorage.setItem('gts_token', response.data.token)
        localStorage.setItem('gts_refresh_token', response.data.refresh_token)
        localStorage.setItem('gts_user_type', response.data.user.user_type)
        // if()
        if (response.data.user.user_type === 'admin') {
          notifyMe(`Welcome back! ${response.data.user.admin.first_name} ${response.data.user.admin.last_name}`, 'success', 'right')
          localStorage.setItem('gts_user', JSON.stringify(response.data.user.admin))
          store.dispatch('user/updateProfileInfo', response.data.user.admin)
          router.push({ name: 'managers' }).catch(() => {})
        } else if (response.data.user.user_type === 'staff' && response.data.user.staff.is_manager === true) {
          notifyMe(`Welcome back! ${response.data.user.staff.first_name} ${response.data.user.staff.last_name}`, 'success', 'right')
          localStorage.setItem('gts_user', JSON.stringify(response.data.user.staff))
          store.dispatch('user/updateProfileInfo', response.data.user.staff)
          router.push({ name: 'statistics' }).catch(() => {})
        } else if (response.data.user.user_type === 'staff' && response.data.user.staff.is_manager === false) {
          notifyMe(`Welcome back! ${response.data.user.staff.first_name} ${response.data.user.staff.last_name}`, 'success', 'right')
          localStorage.setItem('gts_user', JSON.stringify(response.data.user.staff))
          store.dispatch('user/updateProfileInfo', response.data.user.staff)
          router.push({ name: 'members' }).catch(() => {})
        } else {
          notifyMe(`Welcome back! ${response.data.user.member.first_name} ${response.data.user.member.last_name}`, 'success', 'right')
          localStorage.setItem('gts_user', JSON.stringify(response.data.user.member))
          store.dispatch('user/updateProfileInfo', response.data.user.member)
          router.push({ name: 'service' }).catch(() => {})
        }

        // router.push({ name: 'home' })
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
    registerMemeber({ commit, dispatch }, data) {
      window.cxSocketApi.talk('register-member', resoponse => {
        notifyMe('You have register successfully, verify your email before you login')
        router.push({ name: 'login' })
        return resoponse
      }, error => notifyMe(error.data, 'danger'), data)
    },
    registerStaff({ }, data) {
      if (data.user_type === 'manager') {
        // eslint-disable-next-line no-param-reassign
        data.is_manager = true
      }
      window.cxSocketApi.talk('create-staff', resoponse => {
        notifyMe('You have register new user successfully')
        const userType = 'staff'
        setTimeout(() => {
          store.dispatch('user/getStaffs', { page: 1, per_page: 10, user_type: userType })
        }, 200)
        return resoponse
      }, error => notifyMe(error.data, 'danger'), data)
    },
    verifyEmail({ commit }, code) {
      return window.cxSocketApi.talk('validate-verification-code', response => {
        notifyMe(response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), code)
    },
    resetPassword({ commit }, data) {
      return window.cxSocketApi.talk('request-password-reset', response => {
        notifyMe('New password is sent to your email!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateRegisterProfile({ commit }, data) {
      window.cxSocketApi.talk('update-member', response => {
        notifyMe('You have update profile successfully!')
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateRegisterStaffProfile({ commit }, data) {
      window.cxSocketApi.talk('update-staff', response => {
        notifyMe('You have update profile successfully!')
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updatePassword({ commit }, data) {
      window.cxSocketApi.talk('update-password', response => {
        notifyMe('You have update your password successfully!')
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
