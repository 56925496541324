export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_SUB_CATEGORIES = 'GET_ALL_SUB_CATEGORIES'
export const GET_ALL_STATES = 'GET_ALL_STATES'
export const GET_ALL_COUNTIES = 'GET_ALL_COUNTIES'
export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS'
export const GET_ALL_PROFILE = 'GET_ALL_PROFILE'
export const GET_ALL_RECOMMENDATION = 'GET_ALL_RECOMMENDATION'
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS'
export const GET_ALL_DAYS = 'GET_ALL_DAYS'
export const GET_ALL_SOCIAL_MEDIA_LIST = 'GET_ALL_SOCIAL_MEDIA_LIST'
export const EMPTY_GALLERY = 'EMPTY_GALLERY'
export const GET_ALL_MERGE_COUNTIES = 'GET_ALL_MERGE_COUNTIES'
export const REMOVE_ALL_MERGE_COUNTIES = 'REMOVE_ALL_MERGE_COUNTIES'
export const GET_ALL_MERGE_SUB_CATEGORIES = 'GET_ALL_MERGE_SUB_CATEGORIES'
export const REMOVE_ALL_MERGE_SUB_CATEGORIES = 'REMOVE_ALL_MERGE_SUB_CATEGORIES'
export const EMPTY_DATA = 'EMPTY_DATA'
export const GET_SINGLE_PROFILE = 'GET_SINGLE_PROFILE'
