/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export const notifyMe = (message, type = 'success', position = 'center') => {
  let background
  if (type === 'success') {
    background = 'linear-gradient(to right, #00b09b, #96c93d)'
  }
  if (type === 'danger') {
    background = 'linear-gradient(to right top, #c6091a, #cb002c, #cf003c, #d1004c, #d1005b)'
  }
  return Toastify({
    text: `${message} !`,
    gravity: 'top', // `top` or `bottom`
    position, // `left`, `center` or `right`
    duration: 3000,
    stopOnFocus: true,
    close: true,
    style: {
      background,
    },
  }).showToast()
}
