export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/auth/Verify.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/auth/Profile.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
]
