import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vidle from 'v-idle'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueLazyload from 'vue-lazyload'
import vSelect from 'vue-select'
import Config from './store/config/host'
import * as truncate from './libs/truncate'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import 'vue-select/dist/vue-select.css'
// 3rd party plugins
import '@/libs/sweet-alerts'
import '@/libs/portal-vue'
import '@/libs/toastification'
import { ConnectxoneInitializer } from './store/config/cxSocket'
// eslint-disable-next-line import/no-unresolved

const cxOne = new ConnectxoneInitializer()
cxOne.cxOneConnect(Config[process.env.NODE_ENV].socketURL, null, 'gts_token', 'gts_refresh_token')
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component('v-select', vSelect)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Vidle)
Vue.use(VueFileAgent)
Vue.use(VueLazyload)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Vue.filter('truncate', truncate);
Object.keys(truncate).forEach(key => {
  Vue.filter(key, truncate[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
