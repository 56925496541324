import { notifyMe } from '@/libs/toast'

export default {
  namespaced: true,
  state: {
    page_contents: null,
    terms_contents: '',
    privacy_contents: '',
    page_footer_contents: null,
    contact_contents: null,
    faq_contents: { content: [] },
  },
  getters: {
    getAllPageContent(state) {
      return state.page_contents
    },
  },
  mutations: {
    GET_ALL_CONTENT(state, data) {
      state.page_contents = data
    },
    GET_ALL_TERMS_CONTENT(state, data) {
      state.terms_contents = data
    },
    GET_ALL_PRIVACY_CONTENT(state, data) {
      state.privacy_contents = data
    },
    GET_ALL_FOOTER_CONTENT(state, data) {
      state.page_footer_contents = data
    },
    GET_ALL_CONTACT_CONTENT(state, data) {
      state.contact_contents = data
    },
    GET_ALL_FAQ_CONTENT(state, data) {
      state.faq_contents = data
    },
  },
  actions: {
    getAllContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllTermsContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_TERMS_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllPrivacyContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_PRIVACY_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllFooterContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_FOOTER_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllContactContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_CONTACT_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllFAQContent({ commit }, data) {
      return window.cxSocketApi.talk('get-page-content', response => {
        commit('GET_ALL_FAQ_CONTENT', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createContent({ dispatch }, data) {
      return window.cxSocketApi.talk('create-page-content', response => {
        setTimeout(() => {
          dispatch('getAllContent', data.name)
        }, 200)
        notifyMe('content created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createTermsContent({ dispatch }, data) {
      return window.cxSocketApi.talk('create-page-content', response => {
        setTimeout(() => {
          dispatch('getAllTermsContent', data.name)
        }, 200)
        notifyMe('content created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateTermsContent({ dispatch }, data) {
      return window.cxSocketApi.talk('update-page-content', response => {
        setTimeout(() => {
          dispatch('getAllTermsContent', data.name)
        }, 200)
        notifyMe('content updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createPrivacyContent({ dispatch }, data) {
      return window.cxSocketApi.talk('create-page-content', response => {
        setTimeout(() => {
          dispatch('getAllPrivacyContent', data.name)
        }, 200)
        notifyMe('content created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updatePrivacyContent({ dispatch }, data) {
      return window.cxSocketApi.talk('update-page-content', response => {
        setTimeout(() => {
          dispatch('getAllPrivacyContent', data.name)
        }, 200)
        notifyMe('content updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },

    deleteContent({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-page-content', response => {
        setTimeout(() => {
          dispatch('getAllContent', data.name)
        }, 200)
        notifyMe('content deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.id)
    },
    updateContent({ dispatch }, data) {
      return window.cxSocketApi.talk('update-page-content', response => {
        setTimeout(() => {
          dispatch('getAllContent', data.name)
        }, 200)
        notifyMe('content updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createFAQContent({ dispatch }, data) {
      return window.cxSocketApi.talk('create-page-content', response => {
        setTimeout(() => {
          dispatch('getAllFAQContent', data.name)
        }, 200)
        notifyMe('content created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteFAQContent({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-page-content', response => {
        setTimeout(() => {
          dispatch('getAllFAQContent', data.name)
        }, 200)
        notifyMe('content deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.id)
    },
    updateFAQContent({ dispatch }, data) {
      return window.cxSocketApi.talk('update-page-content', response => {
        setTimeout(() => {
          dispatch('getAllFAQContent', data.name)
        }, 200)
        notifyMe('content updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
