import { notifyMe } from '../../libs/toast'
import {
  GET_ADMIN, GET_ADMINS, GET_AVATAR, GET_MEMBER, GET_MEMBERS, GET_PROFILE, GET_STAFF, GET_STAFFS,
} from './types'

export default {
  namespaced: true,
  state: {
    members: [],
    member: {},
    admins: [],
    admin: {},
    staffs: [],
    staff: {},
    profile: {},
    total: 1,
  },
  getters: {
    getAdmins(state) {
      return state.admins
    },
    getMembers(state) {
      return state.members
    },
    getStaffs(state) {
      return state.staffs
    },
  },
  mutations: {
    [GET_ADMINS](state, data) {
      state.admins = data.data
      state.total = data.total
    },
    [GET_MEMBERS](state, data) {
      state.members = data.data
      state.total = data.total
    },
    [GET_ADMINS](state, data) {
      state.admins = data.data
      state.total = data.total
    },
    [GET_STAFFS](state, data) {
      state.staffs = data.data
      state.total = data.total
    },
    [GET_ADMIN](state, data) {
      state.admin = data
    },
    [GET_MEMBER](state, data) {
      state.member = data
    },
    [GET_STAFF](state, data) {
      state.staff = data
    },
    [GET_PROFILE](state, data) {
      let userData = JSON.parse(localStorage.getItem('gts_user'))
      userData = data
      localStorage.setItem('gts_user', JSON.stringify(userData))
      state.profile = userData
    },
    [GET_AVATAR](state, data) {
      const userData = JSON.parse(localStorage.getItem('gts_user'))
      userData.avatar = data
      localStorage.setItem('gts_user', JSON.stringify(userData))
      state.profile = userData
    },
    UPDATE_PROFILE(state, data) {
      state.profile = data
    },
  },
  actions: {
    getMembers({ commit }, data) {
      return window.cxSocketApi.talk('get-members', response => {
        commit(GET_MEMBERS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAdmins({ commit }, data) {
      return window.cxSocketApi.talk('get-admins', response => {
        commit(GET_ADMINS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getStaffs({ commit }, data) {
      return window.cxSocketApi.talk('get-staffs', response => {
        commit(GET_STAFFS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getMember({ commit }, data) {
      return window.cxSocketApi.talk('get-member', response => {
        commit(GET_MEMBER, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAdmin({ commit }, data) {
      return window.cxSocketApi.talk('get-admin', response => {
        commit(GET_ADMINS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getStaff({ commit }, data) {
      return window.cxSocketApi.talk('get-staff', response => {
        commit(GET_STAFF, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateProfile({ commit }, data) {
      const userType = localStorage.getItem('gts_user_type')
      let action
      if (userType === 'admin') {
        action = 'update-admin'
      } else if (userType === 'staff') {
        action = 'update-staff'
      } else if (userType === 'member') {
        action = 'update-member'
      }
      window.cxSocketApi.talk(action, response => {
        notifyMe('You have update profile successfully!')
        commit(GET_PROFILE, response.data)
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
    uploadProfilePhoto({ commit }, data) {
      const userType = localStorage.getItem('gts_user_type')
      let action
      if (userType === 'admin') {
        action = 'update-admin-avatar'
      } else if (userType === 'staff') {
        action = 'update-staff-avatar'
      } else if (userType === 'member') {
        action = 'update-member-avatar'
      }
      window.cxSocketApi.talk(action, response => {
        notifyMe('You have update your photo successfully!')
        commit(GET_AVATAR, response.data)
        return response
      }, error => notifyMe(error.data, 'danger'), data)
    },
    setProfile({ commit }) {
      const user = JSON.parse(localStorage.getItem('gts_user'))
      commit('UPDATE_PROFILE', user)
    },
    updateProfileInfo({ commit }, data) {
      commit('UPDATE_PROFILE', data)
    },
    toggleUser({ }, data) {
      return window.cxSocketApi.talk('toggle-user-login-status', response => {
        if (data.can_login === true) {
          notifyMe('User is active now!')
        } else {
          notifyMe('User is inactive now!')
        }
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
