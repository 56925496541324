import { notifyMe } from '@/libs/toast'
import { GET_ALL_MERGE_SUB_CATEGORIES, GET_ALL_SUB_CATEGORIES, REMOVE_ALL_MERGE_SUB_CATEGORIES } from './types'

export default {
  namespaced: true,
  state: {
    all_sub_categories: [],
    sub_categories: [],
    merge_sub_categories: [],
  },
  getters: {
    getSubCategories(state) {
      return state.sub_categories
    },
  },
  mutations: {
    [GET_ALL_SUB_CATEGORIES](state, data) {
      state.all_sub_categories = data
    },
    GET_SUB_CATEGORIES(state, id) {
      const subCategories = state.all_sub_categories.filter(item => item.parent_id === id)
      state.sub_categories = subCategories
    },
    [GET_ALL_MERGE_SUB_CATEGORIES](state, data) {
      data.forEach(item => {
        if (item.parent_id !== null) {
          state.merge_sub_categories.push(item)
        }
      })
    },
    [REMOVE_ALL_MERGE_SUB_CATEGORIES](state, id) {
      const newItems = state.merge_sub_categories.filter(item => item.parent_id !== id)
      state.merge_sub_categories = newItems
    },
  },
  actions: {
    getAllSubCategories({ commit }, data) {
      return window.cxSocketApi.talk('get-categories', response => {
        const items = response.data.filter(item => item.parent_id !== null)
        commit(GET_ALL_SUB_CATEGORIES, items)
        return items
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getSubCategories({ commit }, id) {
      commit('GET_SUB_CATEGORIES', id)
    },
    mergeSubCategories({ commit }, data) {
      return window.cxSocketApi.talk('get-sub-categories', response => {
        commit(GET_ALL_MERGE_SUB_CATEGORIES, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    removeMergeSubCategories({ commit }, id) {
      commit(REMOVE_ALL_MERGE_SUB_CATEGORIES, id)
    },
    createSubCategory({ dispatch }, data) {
      return window.cxSocketApi.talk('create-category', response => {
        dispatch('getAllSubCategories')
        dispatch('getSubCategories', data.parent_id)
        notifyMe('Sub Category created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteSubCategory({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-category', response => {
        dispatch('getAllSubCategories')
        dispatch('getSubCategories', data.parent_id)
        notifyMe('Sub Category deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.id)
    },
    updateSubCategory({ dispatch }, data) {
      return window.cxSocketApi.talk('update-category', response => {
        dispatch('getAllSubCategories')
        dispatch('getSubCategories', data.parent_id)
        notifyMe('Sub Category updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
