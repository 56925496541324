import { notifyMe } from "@/libs/toast"

export default {
  namespaced: true,
  state: {
    dashboard_statistics: {
      businesses_recommended: 0,
      member_businesses_viewed: 0,
      messages_sent_to_vendors: 0,
      total_business_reviews: 0,
      total_business_searches: 0,
      vendor_businesses_viewed: 0,
    },
    vendor_statistics: [],
    member_statistics: [],
    total: 0,
  },

  getters: {
    getVendorStatistics(state) {
      return state.vendor_statistics
    },
    getMemberStatistics(state) {
      return state.member_statistics
    },
  },
  mutations: {
    GET_BUSINESS_STATISTICS(state, data) {
      state.dashboard_statistics = data
    },
    GET_VENDOR_STATISTICS(state, data) {
      state.vendor_statistics = data.data
      state.total = data.total
    },
    GET_MEMBER_STATISTICS(state, data) {
      state.member_statistics = data.data
      state.total = data.total
    },
  },
  actions: {
    getStatistics({ commit }, data) {
      return window.cxSocketApi.talk('dashboard-statistics', response => {
        commit('GET_BUSINESS_STATISTICS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getVendorStatistics({ commit }, data) {
      return window.cxSocketApi.talk('get-business-statistics', response => {
        commit('GET_VENDOR_STATISTICS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getMemberStatistics({ commit }, data) {
      return window.cxSocketApi.talk('get-member-statistics', response => {
        commit('GET_MEMBER_STATISTICS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
