import { notifyMe } from "@/libs/toast"

export default {
  namespaced: true,
  state: {
    service_area: [],
  },
  //   getters: {
  //     getHours(state) {
  //       return state.hours
  //     },
  //   },
  mutations: {
    EMPTY_DATA_LIST(state, data) {
      state.service_area = data
    },
    UPDATE_SERVICES(state, data) {
      const service = state.service_area.filter(item => item.state_id !== null && item.county_id !== null)
      const singleItem = service[0]
      singleItem.id = data.id
    },
  },
  actions: {
    emptyServiceAreaData({ commit }) {
      return commit('EMPTY_DATA_LIST', [])
    },
    addSerivceArea({ commit }, data) {
      return window.cxSocketApi.talk('add-service-area-to-business-profile', response => {
        notifyMe('service area added successfully')
        const item = response.data
        commit('UPDATE_SERVICES', item)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateSerivceArea({}, data) {
      return window.cxSocketApi.talk('update-service-area', response => {
        notifyMe('service area updated successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteSerivceArea({}, data) {
      return window.cxSocketApi.talk('remove-service-area-from-business-profile', response => {
        notifyMe('service area deleted successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
