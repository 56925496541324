import { notifyMe } from '@/libs/toast'
import { GET_ALL_STATES } from './types'

export default {
  namespaced: true,
  state: {
    states: [],
  },
  getters: {
    getAllState(state) {
      return state.states
    },
  },
  mutations: {
    [GET_ALL_STATES](state, data) {
      state.states = data
    },
  },
  actions: {
    getStates({ commit }, data) {
      return window.cxSocketApi.talk('get-states', response => {
        commit(GET_ALL_STATES, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createState({ dispatch }, data) {
      return window.cxSocketApi.talk('create-state', response => {
        setTimeout(() => {
          dispatch('getStates')
        }, 200)
        notifyMe('State created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteState({ dispatch }, id) {
      return window.cxSocketApi.talk('delete-state', response => {
        setTimeout(() => {
          dispatch('getStates')
        }, 200)
        notifyMe('State deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), id)
    },
    updateState({ dispatch }, data) {
      return window.cxSocketApi.talk('update-state', response => {
        setTimeout(() => {
          dispatch('getStates')
        }, 200)
        notifyMe('State updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
