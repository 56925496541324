import { notifyMe } from '@/libs/toast'
import { GET_ALL_COUNTIES, GET_ALL_MERGE_COUNTIES, REMOVE_ALL_MERGE_COUNTIES } from './types'

export default {
  namespaced: true,
  state: {
    all_counties: [],
    counties: [],
    merge_counties: [],
  },
  getters: {
    getAllCounty(state) {
      return state.counties
    },
    getAllMergeCounties(state) {
      return state.merge_counties
    },
  },
  mutations: {
    [GET_ALL_COUNTIES](state, data) {
      state.all_counties = data
    },
    GET_ALL_SELECTED_COUNTIES(state, id) {
      const counties = state.all_counties.filter(item => item.state_id === id)
      state.counties = counties
    },
    [GET_ALL_MERGE_COUNTIES](state, data) {
      data.forEach(item => state.merge_counties.push(item))
    },
    [REMOVE_ALL_MERGE_COUNTIES](state, id) {
      const newItems = state.merge_counties.filter(item => item.state_id !== id)
      state.merge_counties = newItems
    },
  },
  actions: {
    getAllCounties({ commit }, data) {
      return window.cxSocketApi.talk('get-all-counties', response => {
        commit(GET_ALL_COUNTIES, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getCounties({ commit }, id) {
      setTimeout(() => {
        commit('GET_ALL_SELECTED_COUNTIES', id)
      }, 300)
    },
    mergeCounties({ commit }, data) {
      return window.cxSocketApi.talk('get-counties', response => {
        commit(GET_ALL_MERGE_COUNTIES, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    removeMergeCounties({ commit }, id) {
      commit(REMOVE_ALL_MERGE_COUNTIES, id)
    },
    createCounty({ dispatch }, data) {
      return window.cxSocketApi.talk('create-county', response => {
        setTimeout(() => {
          dispatch('getAllCounties')
          dispatch('getCounties', data.state_id)
        }, 200)
        notifyMe('County created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteCounty({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-county', response => {
        setTimeout(() => {
          dispatch('getAllCounties')
          dispatch('getCounties', data.state_id)
        }, 200)
        notifyMe('County deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.id)
    },
    updateCounty({ dispatch }, data) {
      return window.cxSocketApi.talk('update-county', response => {
        setTimeout(() => {
          dispatch('getAllCounties')
          dispatch('getCounties', data.state_id)
        }, 200)
        notifyMe('County updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
