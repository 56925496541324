import { notifyMe } from '@/libs/toast'
import { GET_ALL_TAGS } from './types'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  getters: {
    getTags(state) {
      return state.tags
    },
  },
  mutations: {
    [GET_ALL_TAGS](state, data) {
      state.tags = data
    },
  },
  actions: {
    getTags({ commit }, data) {
      return window.cxSocketApi.talk('get-tags', response => {
        commit(GET_ALL_TAGS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createTag({ dispatch }, data) {
      return window.cxSocketApi.talk('create-tag', response => {
        dispatch('getTags')
        notifyMe('tag created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteTag({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-tag', response => {
        dispatch('getTags')
        notifyMe('tag deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateTag({ dispatch }, data) {
      return window.cxSocketApi.talk('update-tag', response => {
        dispatch('getTags')
        notifyMe('tag updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
