import { notifyMe } from "@/libs/toast"

export default {
  namespaced: true,
  state: {
    licenses: [],
  },
  //   getters: {
  //     getHours(state) {
  //       return state.hours
  //     },
  //   },
  mutations: {
    EMPTY_DATA_LIST(state, data) {
      state.licenses = data
    },
    UPDATE_LICENSES(state, data) {
      const service = state.licenses.filter(item => item.license !== '' && item.license_expiration_date !== '')
      const singleItem = service[0]
      singleItem.id = data.id
    },
  },
  actions: {
    emptyLicenseData({ commit }) {
      return commit('EMPTY_DATA_LIST', [])
    },
    addLicenses({ commit }, data) {
      return window.cxSocketApi.talk('add-licenses-to-business-profile', response => {
        notifyMe('license added successfully')
        response.data.licenses.forEach(item => commit('UPDATE_LICENSES', item))
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateLicense({}, data) {
      return window.cxSocketApi.talk('update-license', response => {
        notifyMe('license handle updated successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteLicense({}, data) {
      return window.cxSocketApi.talk('remove-license-from-business-profile', response => {
        notifyMe('license deleted successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
