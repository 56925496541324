import { notifyMe } from '@/libs/toast'

export default {
  namespaced: true,
  state: {
    transactions: [],
    business_transaction: [],
  },
  getters: {
    getTransaction(state) {
      return state.transactions
    },
  },
  mutations: {
    GET_ALL_TRANSACTION(state, data) {
      state.transactions = data
    },
    GET_TRANSACTION_BY_BUSINESS_PROFILE(state, data) {
      state.business_transaction = data
    },
  },
  actions: {
    getTransactions({ commit }, data) {
      return window.cxSocketApi.talk('get-transactions', response => {
        commit('GET_ALL_TRANSACTION', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getTransactionByBusinessProfile({ commit }, data) {
      return window.cxSocketApi.talk('get-business-profile-transactions', response => {
        commit('GET_TRANSACTION_BY_BUSINESS_PROFILE', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createTransaction({ dispatch }, data) {
      return window.cxSocketApi.talk('create-transaction', response => {
        // dispatch('getTransactions')
        notifyMe('transaction created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteTransaction({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-transaction', response => {
        // dispatch('getTransactions')
        notifyMe('transaction deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteAuthorizedState({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-authorized-state', response => {
        // dispatch('getTransactions')
        notifyMe('authorized state deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateTransaction({ dispatch }, data) {
      return window.cxSocketApi.talk('update-transaction', response => {
        // dispatch('getTransactions')
        notifyMe('transaction updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
