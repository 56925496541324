export default {
  production: {
    echoURL: 'https://api.thegotosource.net/api/v1/',
    baseURL: 'https://api.thegotosource.net/api/v1/',
    mainURL: 'https://api.thegotosource.net.net',
    socketURL: 'wss://api.thegotosource.net:6001',
  },

  development: {
    echoURL: 'https://api.thegotosource.net/api/v1/',
    baseURL: 'https://api.thegotosource.net/api/v1/',
    mainURL: 'https://api.thegotosource.net.net',
    socketURL: 'wss://api.thegotosource.net:6001',
  },

  // development: {
  //   echoURL: 'http://127.0.0.1:8000/api/v1/',
  //   baseURL: 'http://127.0.0.1:8000/api/v1/',
  //   mainURL: 'http://127.0.0.1:8000',
  //   socketURL: 'ws://127.0.0.1:6002',
  // },
}
