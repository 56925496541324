import { notifyMe } from '@/libs/toast'
import { GET_ALL_CONTACTS } from './types'

export default {
  namespaced: true,
  state: {
    contacts: [],
  },
  getters: {
    getContacts(state) {
      return state.contacts
    },
  },
  mutations: {
    [GET_ALL_CONTACTS](state, data) {
      state.contacts = data
    },
    SORTCONTACTS(state, item) {
      if (item.sort_type === 'DESC') {
        state.contacts = item.data.sort((a, b) => a.full_name.localeCompare(b.full_name))
      } else {
        state.contacts = item.data.sort((a, b) => b.full_name.localeCompare(a.full_name))
      }
    },
  },
  actions: {
    getContacts({ commit }, data) {
      return window.cxSocketApi.talk('get-contacts', response => {
        commit(GET_ALL_CONTACTS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getUserContacts({ commit }, data) {
      return window.cxSocketApi.talk('get-member-contacts', response => {
        commit(GET_ALL_CONTACTS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    sortContact({ commit }, data) {
      return commit('SORTCONTACTS', data)
    },
    getMemberContacts({ commit }, data) {
      return window.cxSocketApi.talk('get-member-contacts', response => {
        commit(GET_ALL_CONTACTS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createContact({ dispatch }, data) {
      return window.cxSocketApi.talk('create-contact', response => {
        setTimeout(() => {
          dispatch('getMemberContacts', data.member_id)
        }, 200)
        notifyMe('Contact created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteContact({ dispatch }, data) {
      return window.cxSocketApi.talk('delete-contact', response => {
        setTimeout(() => {
          dispatch('getMemberContacts', data.member_id)
        }, 200)
        notifyMe('Contact deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.id)
    },
    updateContact({ dispatch }, data) {
      return window.cxSocketApi.talk('update-contact', response => {
        setTimeout(() => {
          dispatch('getMemberContacts', data.member_id)
        }, 200)
        notifyMe('Contact updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    contactUs({ dispatch }, data) {
      return window.cxSocketApi.talk('contact-us', response => {
        notifyMe('Thank you for contacting us, we will respond in the next 12 to 24hrs!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
