import { notifyMe } from "@/libs/toast"

export default {
  namespaced: true,
  state: {
    socia_media: [],
  },
  //   getters: {
  //     getHours(state) {
  //       return state.hours
  //     },
  //   },
  mutations: {
    GET_ALL_HOURS(state, data) {
      state.hours = data
    },
    EMPTY_DATA_LIST(state, data) {
      state.socia_media = data
    },
    UPDATE_SOCIA_HANDLES(state, data) {
      const service = state.socia_media.filter(item => item.type !== '' && item.link !== '')
      const singleItem = service[0]
      singleItem.id = data.id
    },
  },
  actions: {
    addHours({ commit }, data) {
      return commit('GET_ALL_HOURS', data)
    },
    emptySocialData({ commit }) {
      return commit('EMPTY_DATA_LIST', [])
    },
    addSocialMediaHandle({ commit }, data) {
      return window.cxSocketApi.talk('add-social-handles-to-business-profile', response => {
        notifyMe('social media handle added successfully')
        response.data.social_handles.forEach(item => commit('UPDATE_SOCIA_HANDLES', item))
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateSocialMediaHandle({}, data) {
      return window.cxSocketApi.talk('update-social-handle', response => {
        notifyMe('social media handle updated successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteSocialMediaHandle({}, data) {
      return window.cxSocketApi.talk('remove-social-handle-from-business-profile', response => {
        notifyMe('social media handle deleted successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
