import { notifyMe } from "@/libs/toast"
import { EMPTY_DATA } from "./types"

export default {
  namespaced: true,
  state: {
    hours: [],
  },
  //   getters: {
  //     getHours(state) {
  //       return state.hours
  //     },
  //   },
  mutations: {
    GET_ALL_HOURS(state, data) {
      state.hours = data
    },
    [EMPTY_DATA](state) {
      state.hours = []
    },
    UPDATE_SERVICES(state, data) {
      const service = state.hours.filter(item => item.day_id !== null && item.business_hours_start !== null && item.business_hours_end !== '')
      const singleItem = service[0]
      singleItem.id = data.id
    },
  },
  actions: {
    addHours({ commit }, data) {
      return commit('GET_ALL_HOURS', data)
    },
    emptyData({ commit }) {
      return commit(EMPTY_DATA)
    },
    addWorkingHours({ commit }, data) {
      return window.cxSocketApi.talk('add-business-hours-to-business-profile', response => {
        notifyMe('working hours added successfully')
        response.data.business_hours.forEach(item => commit('UPDATE_SERVICES', item))
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateWorkingHours({}, data) {
      return window.cxSocketApi.talk('update-business-hour', response => {
        notifyMe('working hours updated successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteWorkingHours({}, data) {
      return window.cxSocketApi.talk('remove-business-hour-from-business-profile', response => {
        notifyMe('working hour deleted successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
