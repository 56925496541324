export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/site/HomePage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/site/Services.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/site/PrivacyPolicy.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/site/Terms.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/site-categories',
    name: 'site-categories',
    component: () => import('@/views/site/SiteCategories.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/services/:id',
    name: 'services-details',
    component: () => import('@/views/site/ServicesDetails.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('@/views/site/Page.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/site/ServiceContact.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/service-reviews',
    name: 'service-reviews',
    component: () => import('@/views/site/ServiceReviews.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/service-recommendations',
    name: 'service-recommendations',
    component: () => import('@/views/site/ServiceRecommendation.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/vendor-businesses',
    name: 'vendor-businesses',
    component: () => import('@/views/site/VendorServices.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('@/views/site/ServiceFavorate.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/profile-settings',
    name: 'profile-settings',
    component: () => import('@/views/site/MemberProfile.vue'),
    meta: {
      layout: 'full',
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/site/ContactUs.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/site/AboutUs.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/site/FAQS.vue'),
    meta: {
      layout: 'full',
    },
  },
]
