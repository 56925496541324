import { notifyMe } from '@/libs/toast'

export default {
  namespaced: true,
  state: {
    service_offer: [],
    services: [],
  },
  //   getters: {
  //     getHours(state) {
  //       return state.hours
  //     },
  //   },
  mutations: {
    EMPTY_DATA_LIST(state, data) {
      state.service_offer = data
    },
    ALL_SERVICES(state, data) {
      state.services = data
    },
    UPDATE_SERVICES(state, data) {
      const service = state.service_offer.filter(item => item.name !== '')
      const singleItem = service[0]
      singleItem.id = data.id
    },
  },
  actions: {
    emptyServiceOfferData({ commit }) {
      return commit('EMPTY_DATA_LIST', [])
    },
    getServices({ commit }, data) {
      return window.cxSocketApi.talk('get-all-services', response => {
        commit('ALL_SERVICES', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    addSerivceOffer({ commit }, data) {
      return window.cxSocketApi.talk('add-services-offered-to-business-profile', response => {
        notifyMe('service offer added successfully')
        response.data.services.forEach(item => commit('UPDATE_SERVICES', item))
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateSerivceOffer({}, data) {
      return window.cxSocketApi.talk('update-service-offered', response => {
        notifyMe('service offer updated successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteSerivceOffer({}, data) {
      return window.cxSocketApi.talk('remove-service-offered-from-business-profile', response => {
        notifyMe('service offer deleted successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
