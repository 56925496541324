import { notifyMe } from '@/libs/toast'
import { GET_ALL_REVIEWS } from './types'

export default {
  namespaced: true,
  state: {
    reviews: [],
    reviews_by_status: [],
    business_profile_reviews: [],
    bad_reviews: [],
    total_reviews: 0,
  },
  getters: {
    getReviews(state) {
      return state.reviews
    },
  },
  mutations: {
    [GET_ALL_REVIEWS](state, data) {
      state.reviews = data
    },
    SORTREVIEW(state, item) {
      if (item.sort_type === 'DESC') {
        state.reviews = item.data.sort((a, b) => a.business_profile.name.localeCompare(b.business_profile.name))
      } else {
        state.reviews = item.data.sort((a, b) => b.business_profile.name.localeCompare(a.business_profile.name))
      }
    },
    GET_ALL_REVIEW_BY_STATUS(state, data) {
      state.reviews_by_status = data
    },
    GET_BUSINESS_PROFILE_REVIEWS(state, data) {
      state.business_profile_reviews = data.data
      state.total_reviews = data.total
    },
    GET_BAD_REVIEWS(state, data) {
      state.bad_reviews = data
    },
  },
  actions: {
    getReviews({ commit }, data) {
      return window.cxSocketApi.talk('get-all-reviews', response => {
        commit(GET_ALL_REVIEWS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getReviewByStatus({ commit }, data) {
      return window.cxSocketApi.talk('get-reviews-by-status', response => {
        commit('GET_ALL_REVIEW_BY_STATUS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getBusinessReviews({ commit }, data) {
      return window.cxSocketApi.talk('get-business-profile-reviews', response => {
        commit('GET_BUSINESS_PROFILE_REVIEWS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getBadReviews({ commit }, data) {
      return window.cxSocketApi.talk('get-bad-reviews', response => {
        commit('GET_BAD_REVIEWS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateReviewStatus({ dispatch }, data) {
      return window.cxSocketApi.talk('update-review-status', response => {
        notifyMe('Review status change!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getMemberReviews({ commit }, data) {
      return window.cxSocketApi.talk('get-member-reviews', response => {
        commit(GET_ALL_REVIEWS, response.data.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createReview({ dispatch }, data) {
      return window.cxSocketApi.talk('post-review', response => {
        dispatch('getReviews')
        notifyMe('Review created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateReview({ dispatch }, data) {
      return window.cxSocketApi.talk('update-review', response => {
        dispatch('getReviews')
        notifyMe('Review updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    sortReview({ commit }, data) {
      return commit('SORTREVIEW', data)
    },
  },
}
