import { notifyMe } from '@/libs/toast'

export default {
  namespaced: true,
  state: {
    recommendations: [],
    vendor_recommendations: [],
    total: 0,
    is_recommendation: false,
  },
  getters: {
    getRecommendations(state) {
      return state.recommendations
    },
  },
  mutations: {
    GET_RECOMMENDATIONS(state, data) {
      state.recommendations = data.data
      state.total = data.total
    },
    GET_VENDOR_RECOMMENDATIONS(state, data) {
      state.vendor_recommendations = data
    },
    UPDATE_RECOMMENDATION_DATA(state, data) {
      state.is_recommendation = data
    },
    SORTRECOMMENDATION(state, item) {
      if (item.sort_type === 'DESC') {
        state.recommendations = item.data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        state.recommendations = item.data.sort((a, b) => b.name.localeCompare(a.name))
      }
    },
  },
  actions: {
    getRecommendations({ commit }, data) {
      return window.cxSocketApi.talk('get-recommended-businesses', response => {
        commit('GET_RECOMMENDATIONS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getVendorsRecommendations({ commit }, data) {
      return window.cxSocketApi.talk('get-vendor-recommendations', response => {
        commit('GET_VENDOR_RECOMMENDATIONS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateRecommendationData({ commit }, data) {
      return commit('UPDATE_RECOMMENDATION_DATA', data)
    },
    sortRecommendation({ commit }, data) {
      return commit('SORTRECOMMENDATION', data)
    },
    sendRecommendation({ }, data) {
      return window.cxSocketApi.talk('recommend-business-profile', response => {
        notifyMe('Thank you for recommending this service!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
