import { notifyMe } from '@/libs/toast'
import AppLoader from '@/libs/AppLoader'
import {
  GET_ALL_DAYS, GET_ALL_PROFILE, GET_ALL_SOCIAL_MEDIA_LIST, EMPTY_GALLERY, EMPTY_DATA, GET_SINGLE_PROFILE,
} from './types'

export default {
  namespaced: true,
  state: {
    active_data: {},
    business_profile_details: null,
    search_message: 'Please select state',
    search_result_text: 'Search Results',
    suspended_businesses: [],
    enable_sort: false,
    total_count: 0,
    total_admin_count: 0,
    center: { lat: 38.8887, lng: -77.0854 },
    business_profiles: [],
    favorites: [],
    total_favorite_count: 0,
    admin_business_profiles: [],
    vendor_single_busienss: {},
    single_profile: {},
    days: [],
    socal_media_list: [],
    maps: { lng: null, lat: null },
    gallery_items: [],
    gallery_item: [],
    cover_photo: { name: '', file: '' },
    address: {
      address1: '',
      city: '',
      state_id: 1,
      county_id: 1,
      zip_code: '',
    },
    param: {
      sort: {
        field: '',
        direction: '',
      },
      states: [],
      counties: [],
      categories: [], // array of category ids,
      sub_categories: [],
      tags: [], // array of tag ids,
      business_days: [], // array of business_day ids,
      search_term: '',
      page: 1, // last business id for pagination,
      per_page: 12, // the number of items required per page
    },
    admin_param: {
      sort: {
        field: '',
        direction: '',
      },
      states: [],
      system_status: [],
      application_status: [],
      counties: [],
      categories: [], // array of category ids,
      sub_categories: [],
      tags: [], // array of tag ids,
      business_days: [], // array of business_day ids,
      search_term: '',
      page: 1, // last business id for pagination,
      per_page: 12, // the number of items required per page
    },
    favorite_param: {
      sort: {
        field: '',
        direction: '',
      },
      states: [],
      counties: [],
      categories: [], // array of category ids,
      sub_categories: [],
      tags: [], // array of tag ids,
      business_days: [], // array of business_day ids,
      search_term: '',
      page: 1, // last business id for pagination,
      per_page: 12, // the number of items required per page
    },
    multiple_param: {
      sort: {
        field: '',
        direction: '',
      },
      state: [],
      counties: [],
      categories: [], // array of category ids,
      sub_categories: [],
      tags: [], // array of tag ids,
      business_days: [], // array of business_day ids,
      search_term: '',
      page: 1, // last business id for pagination,
      per_page: 12, // the number of items required per page
    },
    profile_cover_photo: '',
  },
  getters: {
    getProfiles(state) {
      return state.business_profiles
    },
    getSingleProfile(state) {
      return state.single_profile
    },
    getDays(state) {
      return state.days
    },
    getSocialMediaList(state) {
      return state.socal_media_list
    },
  },
  mutations: {
    [GET_ALL_PROFILE](state, data) {
      state.total_count = data.total
      state.business_profiles = data.data
    },
    EMPTY_ALL_PROFILE(state, data) {
      state.total_count = data.total
      state.business_profiles = data.data
    },
    EMPTY_ALL_PROFILE_DETAILS(state, data) {
      state.business_profile_details = data
    },
    GET_ALL_FAVORITES(state, data) {
      state.total_favorite_count = data.total
      state.favorites = data.data
    },
    GET_SUSPENDED_BUSINESSES(state, data) {
      state.suspended_businesses = data
    },
    SORTfAVORITES(state, item) {
      if (item.sort_type === 'DESC') {
        state.favorites = item.data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        state.favorites = item.data.sort((a, b) => b.name.localeCompare(a.name))
      }
    },
    SORT_VENDOR_BUSINESS(state, item) {
      if (item.sort_type === 'DESC') {
        state.admin_business_profiles = item.data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        state.admin_business_profiles = item.data.sort((a, b) => b.name.localeCompare(a.name))
      }
    },
    GET_ALL_ADMIN_PROFILE(state, data) {
      state.total_admin_count = data.total
      state.admin_business_profiles = data.data
    },
    [GET_SINGLE_PROFILE](state, data) {
      state.single_profile = data
    },
    GET_VENDOR_SINGLE_PROFILE(state, data) {
      state.vendor_single_busienss = data
    },
    [GET_ALL_DAYS](state, data) {
      state.days = data
    },
    GET_DEFAULT_LOCATION(state, data) {
      state.active_data = data
    },
    [GET_ALL_SOCIAL_MEDIA_LIST](state, data) {
      state.socal_media_list = data
    },
    [EMPTY_GALLERY](state, data) {
      state.gallery_items = data
    },
    EMPTY_COVER_PHOTO(state, data) {
      state.profile_cover_photo = data
    },
    [EMPTY_DATA](state, data) {
      state.days = []
      state.socal_media_list = []
      state.maps = { lng: null, lat: null }
      state.gallery_items = []
      state.cover_photo = { name: '', file: '' }
      state.address = {
        address1: '',
        city: '',
        state_id: 1,
        county_id: 1,
        zip_code: '',
      }
    },
    EMPTY_LAST_SEARCH_PARAM(state, data) {
      state.param = data
      state.business_profiles = []
      state.total_count = 0
    },
    EMPTY_DEFAULT_LOCATION(state, data) {
      state.active_data = data
    },
    EmptyParam(state) {
      state.admin_param = {
        sort: {
          field: '',
          direction: '',
        },
        states: [],
        system_status: [],
        application_status: [],
        counties: [],
        categories: [], // array of category ids,
        sub_categories: [],
        tags: [], // array of tag ids,
        business_days: [], // array of business_day ids,
        search_term: '',
        page: 1, // last business id for pagination,
        per_page: 12, // the number of items required per page
      }
    },
    EMPTY_ADDRESS(state, data) {
      state.address = data
    },
    GET_GALLERY(state, data) {
      data.forEach(item => {
        state.gallery_item.push(item)
      })
    },
    GET_GALLERIES(state, data) {
      data.forEach(item => {
        state.gallery_items.push(item)
      })
    },
    UPDATE_MESSAGE(state, data) {
      state.search_message = data
    },
    UPDATE_SEARCH_RESULT(state, data) {
      state.search_result_text = data
    },
    UPDATE_ENABLE_SORT(state, data) {
      state.enable_sort = data
    },
    GET_PROFILE_DETAILS(state, data) {
      state.business_profile_details = data
    },
    GET_PROFILE_COVER_PHOTO(state, data) {
      state.profile_cover_photo = data
    },
  },
  actions: {
    getBusinessProfile({ commit }, data) {
      return window.cxSocketApi.talk('get-admin-business-profiles', response => {
        commit('GET_ALL_ADMIN_PROFILE', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getSingleBusinessProfile({ commit }, data) {
      return window.cxSocketApi.talk('get-business-profile', response => {
        commit(GET_SINGLE_PROFILE, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getSingleBusinessProfileBySlug({ commit }, data) {
      return window.cxSocketApi.talk('get-business-profile-by-slug', response => {
        commit(GET_SINGLE_PROFILE, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getBusinessProfileListing({ commit }, data) {
      return window.cxSocketApi.talk('get-business-profile-listing', response => {
        commit(GET_ALL_PROFILE, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getSuspendedBusiness({ commit }, data) {
      return window.cxSocketApi.talk('get-blocked-businesses', response => {
        commit('GET_SUSPENDED_BUSINESSES', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getProfileFavorite({ commit }, data) {
      return window.cxSocketApi.talk('get-favorite-profiles', response => {
        commit('GET_ALL_FAVORITES', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getProfileVendor({ commit }, data) {
      return window.cxSocketApi.talk('get-vendor-businesses', response => {
        // for (let i = 0; i < response.data.length; i++) {
        //   console.log(response.data[i].id, response.data[i].name)
        // }
        commit('GET_ALL_ADMIN_PROFILE', { data: response.data })
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getVendorSingleProfile({ commit }, data) {
      return window.cxSocketApi.talk('get-vendor-business-details', response => {
        commit('GET_VENDOR_SINGLE_PROFILE', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getDays({ commit }, data) {
      return window.cxSocketApi.talk('get-days', response => {
        commit(GET_ALL_DAYS, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    emptyGallery({ commit }, data) {
      return commit(EMPTY_GALLERY, data)
    },
    emptyBusinessProfileDetails({ commit }, data) {
      return commit('EMPTY_ALL_PROFILE_DETAILS', data)
    },
    emptyCoverPhoto({ commit }, data) {
      return commit('EMPTY_COVER_PHOTO', data)
    },
    getGalleries({ commit }, data) {
      commit('GET_GALLERIES', data)
    },
    getProfileDetails({ commit }, data) {
      return commit('GET_PROFILE_DETAILS', data)
    },
    getProfileCoverPhoto({ commit }, data) {
      return commit('GET_PROFILE_COVER_PHOTO', data)
    },
    updateProfileCoverPhoto({ commit }, data) {
      return window.cxSocketApi.talk('update-business-profile-cover', response => {
        commit('GET_PROFILE_COVER_PHOTO', response.data)
        notifyMe('cover photo change successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getSingleProfileDetails({ commit }, data) {
      return window.cxSocketApi.talk('get-vendor-business-details', response => {
        commit('GET_PROFILE_DETAILS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    sortFavorite({ commit }, data) {
      return commit('SORTfAVORITES', data)
    },
    sortVendorBusiness({ commit }, data) {
      return commit('SORT_VENDOR_BUSINESS', data)
    },
    updateMessage({ commit }, data) {
      return commit('UPDATE_MESSAGE', data)
    },
    EmptyProfileList({ commit }) {
      return commit('EMPTY_ALL_PROFILE', { data: [], total: 0 })
    },
    updateSearchResult({ commit }, data) {
      return commit('UPDATE_SEARCH_RESULT', data)
    },
    emptyAddress({ commit }) {
      const data = {
        address1: '',
        city: '',
        state_id: 1,
        county_id: 1,
        zip_code: '',
      }
      return commit('EMPTY_ADDRESS', data)
    },
    getLastSearch({ commit }, data) {
      commit('GET_DEFAULT_LOCATION', data)
      localStorage.setItem('gts_last_search', JSON.stringify(data))
    },
    emptyParam({ commit }) {
      return commit('EmptyParam')
    },
    emptyListParam({ commit }) {
      const data = {
        sort: {
          field: '',
          direction: '',
        },
        states: [],
        counties: [],
        categories: [], // array of category ids,
        sub_categories: [],
        tags: [], // array of tag ids,
        business_days: [], // array of business_day ids,
        search_term: '',
        page: 1, // last business id for pagination,
        per_page: 12, // the number of items required per page
      }
      return commit('EMPTY_LAST_SEARCH_PARAM', data)
    },
    emptyDefaultList({ commit }) {
      return commit('EMPTY_DEFAULT_LOCATION', {})
    },
    emptyData({ commit }) {
      return commit(EMPTY_DATA)
    },
    enableSort({ commit }, data) {
      return commit('UPDATE_ENABLE_SORT', data)
    },
    getSocialMedia({ commit }, data) {
      return window.cxSocketApi.talk('get-social-media-list', response => {
        commit(GET_ALL_SOCIAL_MEDIA_LIST, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    addToFavourite({ dispatch }, data) {
      return window.cxSocketApi.talk('toggle-favorite-profile', response => {
        setTimeout(() => {
          dispatch('getProfileFavorite', data.param)
        }, 200)
        if (data.item.favorite === true) {
          notifyMe('Business Profile added to favorite successfully!')
        } else {
          notifyMe('Profile remove from favorite successfully!')
        }
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.item)
    },
    postReview({ dispatch }, data) {
      return window.cxSocketApi.talk('post-review', response => {
        setTimeout(() => {
          dispatch('getSingleBusinessProfile', data.business_profile_id)
        }, 200)
        notifyMe('Review posted!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    reportReview({ dispatch }, data) {
      return window.cxSocketApi.talk('report-review', response => {
        setTimeout(() => {
          dispatch('getSingleBusinessProfile', data.business_profile_id)
        }, 200)
        notifyMe('Review reported!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateReview({ dispatch }, data) {
      return window.cxSocketApi.talk('update-review', response => {
        setTimeout(() => {
          dispatch('getSingleBusinessProfile', data.business_profile_id)
        }, 200)
        notifyMe('Review posted!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    updateMemberReview({ dispatch }, data) {
      return window.cxSocketApi.talk('update-review', response => {
        notifyMe('Review updated!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    shareBusiness({}, data) {
      return window.cxSocketApi.talk('share-business-profile', response => {
        notifyMe(`Business link share to ${data.emails[0]} successfully`)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    addSingleProfileToFavourite({ dispatch }, data) {
      return window.cxSocketApi.talk('toggle-favorite-profile', response => {
        if (data.favorite === true) {
          notifyMe('Business Profile added to favorite successfully!')
        } else {
          notifyMe('Profile remove from favorite successfully!')
        }
        setTimeout(() => {
          dispatch('getSingleBusinessProfile', data.business_profile_id)
        }, 200)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    // eslint-disable-next-line no-empty-pattern
    contactUs({ }, data) {
      return window.cxSocketApi.talk('send-message-to-contact-person', response => {
        notifyMe('Message sent!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    toggleBusinessProfile({ }, data) {
      return window.cxSocketApi.talk('toggle-business-profile-status', response => {
        if (data.status === true) {
          notifyMe('Business profile is active now!')
        } else {
          notifyMe('Business profile is inactive now!')
        }

        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    createProfile({ dispatch }, data) {
      AppLoader.open()
      return window.cxSocketApi.talk('create-business-profile-with-relations', response => {
        AppLoader.close()
        notifyMe('Profile created successfully!')
        setTimeout(() => {
          dispatch('getBusinessProfile', data.param)
        }, 100)
        return response.data
      }, error => {
        AppLoader.close()
        notifyMe(error.data, 'danger')
      }, data.item)
    },
    changeApplicationStatus({ dispatch }, data) {
      return window.cxSocketApi.talk('update-business-profile-status', response => {
        if (data.user_type === 'vendor') {
          setTimeout(() => {
            dispatch('getProfileVendor', data.param)
          }, 100)
        } else {
          setTimeout(() => {
            dispatch('getBusinessProfile', data.param)
          }, 100)
        }
        notifyMe('business profile status change successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.item)
    },
    deleteProfile({ dispatch }, data) {
      return window.cxSocketApi.talk('archive-business-profile', response => {
        dispatch('getBusinessProfile', data.param)
        notifyMe('Profile deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), { id: data.id, archived: data.archived })
    },
    updateProfile({ dispatch }, data) {
      return window.cxSocketApi.talk('update-business-profile', response => {
        if (data.user_type === 'vendor') {
          setTimeout(() => {
            dispatch('getProfileVendor', data.param)
          }, 100)
        } else {
          setTimeout(() => {
            dispatch('getBusinessProfile', data.param)
          }, 100)
        }
        notifyMe('Profile updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.item)
    },
    addGallery({ commit }, data) {
      return window.cxSocketApi.talk('add-gallery-items-to-business-profile', response => {
        commit('GET_GALLERY', response.data)
        notifyMe('Gallery added successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteGallery({ dispatch }, data) {
      return window.cxSocketApi.talk('remove-gallery-item-from-business-profile', response => {
        notifyMe('gallery remove successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    addTags({ commit }, data) {
      return window.cxSocketApi.talk('add-tags-to-business-profile', response => {
        notifyMe('tag added successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },

    deleteTags({ dispatch }, data) {
      return window.cxSocketApi.talk('remove-tag-from-business-profile', response => {
        notifyMe('tag remove successfully')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
