import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import category from './admin/category'
import subCategory from './admin/sub_category'
import state from './admin/state'
import county from './admin/county'
import tag from './admin/tag'
import contact from './admin/contact'
import businessProfile from './admin/businessprofile'
import review from './admin/reviews'
import workingHours from './admin/working_hours'
import socialMedia from './admin/social_media'
import recommend from './admin/recommend'
import serviceArea from './admin/service_area'
import serviceOffer from './admin/service_offer'
import statistic from './admin/statistics'
import transaction from './admin/transaction'
import backup from './admin/backup'
import page_content from './admin/page_content'
import license from './admin/license'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    category,
    subCategory,
    state,
    county,
    tag,
    contact,
    businessProfile,
    review,
    workingHours,
    socialMedia,
    recommend,
    serviceArea,
    serviceOffer,
    statistic,
    transaction,
    backup,
    license,
    page_content,
    user,
  },
  strict: process.env.DEV,
})
