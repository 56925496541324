export default [
  {
    path: '/business-profile',
    name: 'business-profile',
    component: () => import('@/views/app/BusinessProfile.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/vendor-business-profile',
    name: 'vendor-business-profile',
    component: () => import('@/views/app/VendorBusinessProfile.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/app/Categories.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/app/ServicesOffers.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/sub-categories',
    name: 'sub-categories',
    component: () => import('@/views/app/SubCategories.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/app/Tags.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/states',
    name: 'states',
    component: () => import('@/views/app/State.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/counties',
    name: 'counties',
    component: () => import('@/views/app/County.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('@/views/app/Transaction.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/recomendations',
    name: 'recomendations',
    component: () => import('@/views/app/Recomendation.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/recomendations',
    name: 'vendor-recomendations',
    component: () => import('@/views/app/VendorRecomendation.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/dashboard',
    name: 'statistics',
    component: () => import('@/views/app/ManagerStatistics.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/reported-reviews',
    name: 'reported-reviews',
    component: () => import('@/views/app/ReportedReviews.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/bad-reviews',
    name: 'bad-reviews',
    component: () => import('@/views/app/BadReviews.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/suspended-business',
    name: 'suspended-business',
    component: () => import('@/views/app/SuspendedBusiness.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/vendor-statistics',
    name: 'vendor-statistics',
    component: () => import('@/views/app/VendorStatistics.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/member-statistics',
    name: 'member-statistics',
    component: () => import('@/views/app/MemberStatistics.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/views/app/Reviews.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/app/Members.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/staffs',
    name: 'staffs',
    component: () => import('@/views/app/Staff.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/managers',
    name: 'managers',
    component: () => import('@/views/app/Manager.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/app/Logs.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/data-center',
    name: 'data-center',
    component: () => import('@/views/app/DataCenter.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/faq-content',
    name: 'faq-content',
    component: () => import('@/views/app/FAQs.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/footer-content',
    name: 'footer-content',
    component: () => import('@/views/app/Footer.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/about-content',
    name: 'about-content',
    component: () => import('@/views/app/AboutContent.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/privacy-content',
    name: 'privacy-content',
    component: () => import('@/views/app/PrivacyPolicyContent.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/terms-content',
    name: 'terms-content',
    component: () => import('@/views/app/Terms&ConditionContent.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
  {
    path: '/contact-content',
    name: 'contact-content',
    component: () => import('@/views/app/ContactContent.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const loggeduser = localStorage.getItem('gts_token')
        if (loggeduser === undefined || loggeduser === null || loggeduser.length === 0) {
          return next({ name: 'login' })
        }
        return next()
      },
    },
  },
]
