import { notifyMe } from '@/libs/toast'

export default {
  namespaced: true,
  state: {
    backups: [],
    restore_backups: [],
  },
  getters: {
    getBackups(state) {
      return state.backups
    },
  },
  mutations: {
    GET_ALL_BACKUPS(state, data) {
      state.backups = data
    },
    GET_ALL_RESTORE_BACKUPS(state, data) {
      state.restore_backups = data
    },
  },
  actions: {
    getAllBackups({ commit }, data) {
      return window.cxSocketApi.talk('get-backups', response => {
        commit('GET_ALL_BACKUPS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllRestoreBackups({ commit }, data) {
      return window.cxSocketApi.talk('get-restore-backups', response => {
        commit('GET_ALL_RESTORE_BACKUPS', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    makeBackups({ dispatch }, data) {
      return window.cxSocketApi.talk('backup-database', response => {
        setTimeout(() => {
          dispatch('getAllBackups', data)
        }, 200)
        notifyMe('Backup created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    restoreBackups({ dispatch }, data) {
      return window.cxSocketApi.talk('restore-database', response => {
        setTimeout(() => {
          dispatch('getAllBackups', data.date)
        }, 200)
        notifyMe('backup restore successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data.name)
    },
  },
}
