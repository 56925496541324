import { notifyMe } from '@/libs/toast'
import { GET_ALL_CATEGORIES } from './types'

export default {
  namespaced: true,
  state: {
    categories: [],
    categories_profile: [],
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
  },
  mutations: {
    [GET_ALL_CATEGORIES](state, data) {
      const items = data.filter(item => item.parent_id === null)
      state.categories = items
    },
    GET_ALL_CATEGORIES_WITH_PROFILES(state, data) {
      // const items = data.data.filter(item => item.parent_id === null)
      state.categories_profile = data
    },
    EMPTY_CATEGORY(state, data) {
      state.categories_profile = data
    },
  },
  actions: {
    getCategories({ commit }, data) {
      return window.cxSocketApi.talk('get-categories', response => {
        commit(GET_ALL_CATEGORIES, response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllCategoryWithProfiles({ commit }, data) {
      return window.cxSocketApi.talk('get-categories-with-profiles', response => {
        commit('GET_ALL_CATEGORIES_WITH_PROFILES', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    getAllCategoryForLocation({ commit }, data) {
      return window.cxSocketApi.talk('get-categories-for-location', response => {
        commit('GET_ALL_CATEGORIES_WITH_PROFILES', response.data)
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    emptyCategory({ commit }) {
      commit('EMPTY_CATEGORY', [])
    },
    createCategory({ dispatch }, data) {
      return window.cxSocketApi.talk('create-category', response => {
        setTimeout(() => {
          dispatch('getCategories')
        }, 200)
        notifyMe('Category created successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
    deleteCategory({ dispatch }, id) {
      return window.cxSocketApi.talk('delete-category', response => {
        setTimeout(() => {
          dispatch('getCategories')
        }, 200)
        notifyMe('Category deleted successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), id)
    },
    updateCategory({ dispatch }, data) {
      return window.cxSocketApi.talk('update-category', response => {
        setTimeout(() => {
          dispatch('getCategories')
        }, 200)
        notifyMe('Category updated successfully!')
        return response.data
      }, error => notifyMe(error.data, 'danger'), data)
    },
  },
}
